import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'components/common';
import Post from 'components/common/PostBody';
import { useDraft } from 'hooks/draft';

const Preview = (props) => {
  const { status, draft } = useDraft(props.draftId);

  return (
    <Layout>
      {draft && <Post post={draft} />}
      {status === 'loading' && <h1 className="py-5 text-center">Fetching draft...</h1>}
      {status === 'error' && <h1 className="py-5 text-center">Error loading draft</h1>}
    </Layout>
  );
};

Preview.propTypes = {
  draftId: PropTypes.string
};

export default Preview;
