import { useEffect, useState } from 'react';
import axios from 'axios';

const DRAFT_API = process.env.GATSBY_DRAFT_API || 'https://us-central1-futurefamily-158021.cloudfunctions.net/blog-preview-draft';

export function useDraft (draftId) {
  const [draft, setDraft] = useState();
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    axios.get(`${DRAFT_API}?id=${draftId}`)
      .then(res => setDraft(res.data.post))
      .then(() => setStatus('finished'))
      .catch(() => setStatus('error'));
  }, [draftId]);

  return { status, draft };
}
